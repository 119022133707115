/* eslint-disable import/no-cycle */
<template>
  <v-container fluid>
    <v-row v-if="hasAuthorityViewRulesMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectGeneralRules">
          Regras Gerais
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityViewLayoutModelMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectLayoutTemplates">
          Modelos de Layout
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityViewCollectorsMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectCollectors">
          Coletores
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasNoAuthorities" align="center" justify="center" class="mt-15">
      <v-col align="center" cols="12">
      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
          <v-icon large color="primary">fas fa-low-vision</v-icon>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
        <p class="text--secondary label">Você não tem permissão para visualizar os menus.</p>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserUtils from '@/shared/utils/user-utils';

export default {
  data: () => ({
    hasNoAuthorities: false,
    hasAuthorityViewRulesMenu: false,
    hasAuthorityViewLayoutModelMenu: false,
    hasAuthorityViewCollectorsMenu: false,
  }),

  created() {
    this.userUtils = new UserUtils();
  },

  mounted() {
    this.loadAuthoritiesViewMenus();
    this.verifyAuthorities();
  },

  methods: {
    async loadAuthoritiesViewMenus() {
      this.hasAuthorityViewRulesMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_regras_gerais' });
      this.hasAuthorityViewLayoutModelMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_modelo_layout' });
      this.hasAuthorityViewCollectorsMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_coletores' });
    },
    verifyAuthorities() {
      if (this.hasAuthorityViewRulesMenu || this.hasAuthorityViewLayoutModelMenu || this.hasAuthorityViewCollectorsMenu) {
        this.hasNoAuthorities = false;
      } else {
        this.hasNoAuthorities = true;
      }
    },
    redirectGeneralRules() {
      this.$router.push({ name: 'Rule' });
    },
    redirectLayoutTemplates() {
      this.$router.push({ name: 'LayoutModel' });
    },
    redirectCollectors() {
      this.$router.push({ name: 'Collectors' });
    },
  },
};
</script>
